/* header */
.flex {
  display: flex;
}

.header-end {
  justify-content: flex-end;
  padding-right: 30px;
}

.account-row {
  background-color: whitesmoke;
}

/* body */
.disable-account {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 6px;
}

.grid-container {
  display: flex;
  width: 100%;
  flex: 4;
  margin: 0px;
  padding: 0px 15px;
  text-align: start;
}

.grid-item {
  padding: 0px;
  overflow: scroll;
}

.list {
  max-height: 675px;
  overflow: scroll;
  padding-top: 0px !important;
  padding-bottom: 10px !important;
  min-width: 225px;
}

.list-sub-header {
  display: flex;
  align-items: center;
  background-color: #FBFBFC !important;
  cursor: pointer;
  color: #3f51b5 !important
}

.list-sub-header span {
  color: gray;
}

.list-item-pointer {
  cursor: pointer;
  background: inherit;
}

.list-item-selected {
  background: lightgray;
}

.list-item-features {
  height: 27px
}

.list-item-detail-key {
  text-transform: uppercase;
  font-size: 12px;
  color: gray;
  font-weight: bold;
  padding-right: 4px;
}

.list-item-detail-key span {
  color: black;
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
}

.list-item-detail-value {
  font-size: 14px;
  font-weight: bold;
}
