body {
  margin: 0;
  font-family: Avenir, "Open Sans", HelveticaNeue-Light, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:invalid {
  border: 1px solid red;
}

.info-box {
  padding: 32px;
  border-radius: 4px;
  background-color: lightblue;
  color: #0f627d;
  display: flex;
  align-items: center;
}

.info-box-icon {
  margin-right: 10px;
}

.page-title {
  font-size: 36px;
  padding: 12px;
  margin-top: 10px;
}

.settings-accordion {
  width: '33%';
  flex-shrink: 0;
}

.settings-accordion[aria-expanded="true"]{
  background-color: lightgray;
  font-weight: bolder;
}