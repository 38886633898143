.nav {
  width: 100%;
}

.Nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  background-color: #1a1a1a;
  color: white;
}

.Nav-link {
  color: lightGray;
  font-size: 16px;
  text-decoration: none;
  padding: 24px;
  font-weight: 700;
}

.Nav-active {
  color: white;
  border-bottom: 4px solid white;
}

.Nav-link:hover {
  color: white;
}

.Nav-left {
  display: flex;
  align-items: center;
}

.Nav-logo {
  padding: 16px;
  margin-right: 40px;
}

.Nav-logout {
  color: white;
  padding: 0 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Nav-logout-icon {
  margin-left: 10px;
}
