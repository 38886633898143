.tag-settings {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.create-tag-input {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 50%;
  margin-top: 30px
}

.tag-table {
  min-width: 50vw;
}

.tag-table-header {
  cursor: pointer;
}

.icon {
  cursor: pointer;
  vertical-align: middle;
  transform: scale(0.5);
}

.tag-search {
  justify-self: flex-start;
  width: 50%
}